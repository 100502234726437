import React from 'react';
import Software from '../../img/DesarrolloSoftware.png';

const DesarrolloSoftwareSection = () => (
    <div className="section section-signup page-header FondoDesarrollo" id="desarrollosoftware">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6"/>
                <div className="col-12 col-md-6">
                    <h1>Desarrollo a la medida</h1>
                    <h3>Desarrollo de Aplicaciones de escritorio, móviles y web.</h3>
                    <button className="btn btn-danger" data-toggle="modal" data-target="#Contactomodal">Contáctanos</button>
                </div>
            </div>
        </div>
    </div>
);

const DesarrolloSoftwareTextoSection = () => (
    <div className="section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-9">
                    <h3>Desarrollo de aplicaciones:</h3>
                    <h4>
                        <ul>
                            <li>Móviles</li>
                            <li>Escritorio</li>
                            <li>Web</li>
                            <li>Servidor</li>
                        </ul>
                    </h4>
                    <h4>Llevamos a la realidad tus ideas.</h4>
                    <p>
                        Utilizamos Tecnologías modernas y actuales para el desarrollo de aplicaciones destinados a todos los
                        sistemas operativos.
                    </p>
                </div>
                <div className="col-12 col-md-3 text-center">
                    <img alt="Desarrollo Software" width="150px" src={Software}/>
                </div>
            </div>
        </div>
    </div>
);

export {DesarrolloSoftwareSection, DesarrolloSoftwareTextoSection};
