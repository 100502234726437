import React from 'react';
import '../css/material-kit.css';
import Logo from '../img/sicailogo.svg';

const Fondo = () => (
    /*
    <div className="page-header header-filter clear-filter purple-filter FondoPrincipal" data-parallax="true">
        <div className="container">
            <div className="row">
                <div className="col-md-8 ml-auto mr-auto">
                    <div className="brand">
                        <p>
                            <img width="70%" alt="logotipo" src={Logo}/>
                        </p>
                        <h2>AVISO URGENTE</h2>
                        <h2>Apreciable contribuyente:</h2>
                        <h4>A partir del 1 de enero de 2022 entró en vigor la versión 4.0. del CFDI, a fin de cumplir con los requisitos obligatorios de los comprobantes fiscales derivados de la reforma al artículo 29-A del CFF. Te recordamos que el próximo 31 de marzo de 2023 concluye el periodo de convivencia de las versiones 3.3 y 4.0 del CFDI. Te invitamos a dar cumplimiento con lo establecido en las disposiciones fiscales en materia de CFDI y emitir tus comprobantes fiscales con la nueva versión de CFDI 4.0.</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>*/
    <div className="page-header header-filter clear-filter purple-filter FondoPrincipal" data-parallax="true">
        <div className="container">
            <div className="row">
                <div className="col-md-8 ml-auto mr-auto">
                    <div className="brand">
                        <p>
                            <img width="70%" alt="logotipo" src={Logo}/>
                        </p>
                        <h3>Plataforma para la administración de negocios, facturación electrónica y fábrica de software
                            personalizado para tí.</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Fondo;
