const alpha = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
    'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z',
    '0','1','2','3','4','5','6','7','8','9'];

function Captcha() {
    let a = '';
    let b = '';
    let c = '';
    let d = '';
    let e = '';
    let f = '';
    let g = '';
    for (let i = 0; i < 6; i++) {
        a = Aleatorio();
        b = Aleatorio();
        c = Aleatorio();
        d = Aleatorio();
        e = Aleatorio();
        f = Aleatorio();
        g = Aleatorio();
    }
    return `${a} ${b} ${c} ${d} ${e} ${f} ${g}`;
}

function ValidCaptcha(Valor1, Valor2){
    const string1 = removeSpaces(Valor1);
    const string2 = removeSpaces(Valor2);
    return string1 === string2;
}

function removeSpaces(string){
    return string.split(' ').join('');
}

function Aleatorio() {
    return alpha[Math.floor(Math.random() * alpha.length)];
}

export {Captcha, ValidCaptcha}
