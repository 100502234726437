import React from 'react';
import Duda from '../../img/dudas.jpg';
import HubspotForm from "react-hubspot-form";
import logosicaiweb from '../../img/logosicaiweb.png';

const MasInformacion = () => {
    return (
        <div className="section bg-light" id="masinfo">
            <div className="container">
                <div className="row py-4">
                    <div className="col-12 col-md-6 text-center p-5">
                        <img alt="Sicai" src={logosicaiweb} className="img-fluid mt-3"/>
                        <h3 className="mt-3">Si te interesó alguno de nuestros servicios llena el formulario y pronto uno de nuestros ejecutivos se comunicará contigo.</h3>
                    </div>
                    <div className="col-12 col-md-6">
                        <HubspotForm
                            portalId='1735765'
                            formId='31fd5863-be40-42d8-8d6b-61ef8455b3ba'
                            onSubmit={() => console.log('Submit!')}
                            onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MasInformacionTexto = () => {
    return(
        <div className="section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <img alt="Dudas" src={Duda} className="img-fluid"/>
                    </div>
                    <div className="col-12 col-md-9">
                        <h3>¿Eres nuevo usuario y quieres empezar a emitir CFDI en línea?</h3>
                        <ul>
                            <li>
                                <p><strong>Primer paso:</strong></p>
                                <p>
                                    Deberás tramitar tu e.firma acudiendo a las oficinas del
                                    SAT de tu localidad. Para conocer los requisitos y tramitar tu e.firma, haz clic
                                    <a rel="noopener noreferrer" href="https://www.sat.gob.mx/aplicacion/16660/genera-y-descarga-tus-archivos-a-traves-de-la-aplicacion-certifica" target="_blank"> aquí.</a>
                                </p>
                            </li>
                            <li>
                                <p><strong>Segundo paso:</strong></p>
                                <p>
                                    Realizar el trámite de tu Certificado de Sello Digital (CSD) desde cualquier Computadora
                                    con conexión a Internet. <a rel="noopener noreferrer" target="_blank" href="https://www.sat.gob.mx/tramites/17507/envia-la-solicitud-para-tu-certificado-de-sello-digital-para-emitir-facturas-electronicas">Como generarlo.</a>
                                </p>
                            </li>
                            <li>
                                <p><strong>Tercer paso:</strong></p>
                                <p>
                                    Una vez tramitado tu Certificado de Sello Digital (CSD) espera de 48 a 72 horas para
                                    poder realizar el proceso de registro, este será el tiempo máximo que el SAT
                                    tardará en agregar en activar tu CSD y agregarlo a la Lista de Contribuyentes Obligados (LSO).
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
export {MasInformacion, MasInformacionTexto};
