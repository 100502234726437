import React from 'react';
import pac from '../../img/pac.png'

const PacSection = () => (
    <div className="section">
        <div className="container">
            <div className="title">
                <h2>Somos Proveedor Autorizado de Certificación (PAC).</h2>
            </div>
            <div className="row">
                <div className="col-12 col-xl-1 text-center">
                    <a href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/pac_digitalfactura.htm" target={"_blank"} rel="noreferrer">
                        <img alt="Pac" className="img-fluid MargenSuperior" src={pac} />
                    </a>
                </div>
                <div className="col-12 col-xl-9">
                    <h3>Desde el año 2011 contamos con la autorización del SAT para la certificación de sus
                        comprobantes. Cumpliendo los requisitos de la ISO 27001.</h3>
                </div>
                <div className="col-12 col-xl-2"/>
            </div>
        </div>
    </div>
);

export default PacSection;
