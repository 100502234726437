import React from 'react';
import Addenda from '../../img/AddendaIcon.png';

const AddendasSection = () => {
  return (
      <div className="section section-signup page-header FondoAddendas" id="addendas">
          <div className="container">
              <div className="row">
                  <div className="col-12 col-md-6"/>
                  <div className="col-12 col-md-6">
                      <h1>Addendas</h1>
                      <h3>Contamos con todas las addendas del mercado.</h3>
                      <button className="btn btn-danger"
                              data-toggle="modal"
                              data-target="#Contactomodal">Contactanos</button>
                  </div>
              </div>
          </div>
      </div>
  );
};

const AddendasSectionTexto = () => {
  return (
      <div className="section">
          <div className="container">
              <div className="row">
                  <div className="col-12 col-md-3 text-center">
                      <img alt="addendas" width="200px" src={Addenda}/>
                  </div>
                  <div className="col-12 col-md-9">
                      <h5>
                          ¿QUÉ ES UNA ADDENDA?
                      </h5>
                      <p className="text-justify">
                          "¿Qué es una Addenda y para que sirve?"
                          La Addenda es un elemento opcional de la factura electrónica o comprobante fiscal digital,
                          en ocasiones es requerida por algún cliente en especifico (receptor de la factura electrónica
                          comunmente grandes cadenas comerciales), prácticamente es una estructura que deberá tener un
                          contenido especifico, que son los datos comerciales requeridos por dicho socio comercial.
                          Es importante aclarar que no pertenece a la información requerida por el SAT, por lo que no
                          tiene efectos fiscales, únicamente comerciales. La addenda es un componente de la Factura
                          Electrónica y es un requisito por el receptor de la misma para el mejor control de sus
                          procesos operativos, no es una obligación fiscal requerida por el Sistema de Administración
                          Tributaria (SAT) pero sí un requerimiento comercial para los sistemas de inventarios,
                          contables y embarques, entre otros, sin la intervención del personal. Debe tener los datos
                          comerciales requeridos por dicho socio comercial tales como contenido específico, número de
                          parte, serie, pieza, cantidad, etc. La addenda es el conjunto de textos que se añaden a una
                          documento sea escrito o electrónico ya terminado o a una de sus partes para completarlo y
                          actualizarlo. Esto garantiza que la información contenida en dicho documento incluye toda la
                          información necesaria para que el proceso de comercio electrónico sea una realidad. La
                          Addenda es un nodo dentro del esquema XML (XSD) definido por el SAT en su Anexo 20 que
                          permite el ingreso de información no fiscal al Comprobante Fiscal Digital, pero que está
                          relacionado con el documento como tal.
                      </p>
                      <h5>
                          ¿DE DONDE NACE LA ADDENDA?
                      </h5>
                      <p className="text-justify">
                          Nace de la necesidad de diferenciar y ubicar más rápido los Comprobantes Fiscales Digitales.
                          De facilitar su clasificación, organización y contabilidad.
                      </p>
                      <h5>
                          ¿QUÉ BENEFICIOS DA UNA ADDENDA?
                      </h5>
                      <p>
                          Simplifican mucho la operación en el manejo de Comprobantes Fiscales Digitales y por
                          consecuencia ahorran dinero.
                      </p>
                      <h5>
                          ¿COMO SE UTILIZA UNA ADDENDA Y QUE VENTAJAS TIENE?
                      </h5>
                      <p>
                          Algunas empresas utilizan la Addenda como sección para agregar más información como emisores
                          (por ejemplo conceptos de estados de cuenta), para que se pueda agregar a los formatos
                          impresos de los CFDs. Otros, lo utilizan para poder controlar los CFDs que reciben.
                          Podrían agregar dentro de la Addenda el número de orden de compra que tiene que se
                          relaciona con la factura electrónica, número de proveedor, quien está enviando el documento,
                          entre otros datos. Como gran receptor, esto puede ser de gran utilidad para poder automatizar
                          flujos de aprobación y rastreo de los CFDs cuando se registren dentro del sistema contable
                          o ERP. Un gran receptor podría recibir un CFD que tenga número de proveedor "ABC123" y al
                          enviarse al ERP se podría disparar una regla que solicite autorizaciones adicionales para
                          llevarlo a cuentas por pagar. Todo esto nos lleva a la automatización de procesos, el gran
                          ahorro para empresas o instituciones de cualquier tamaño.
                      </p>
                      <h5>
                          ¿QUÉ ALTERNATIVAS HAY PARA INCORPORAR LA ADDENDA COMO EMISOR?
                      </h5>
                      <p>
                          Aunque suene ilógico, muchos emisores prefieren pagar una tarifa para poder mandar todos sus
                          CFDs a sus clientes que requieren una Addenda por todo lo que implica. Aunque podría sonar
                          caro en un principio, es mucho menor el gasto de pagar por este servicio de envío que pagar
                          por estar desarrollando y manteniendo mecanismos de integración con los clientes importantes.
                          Estos proveedores son conocidos como "Burós de Servicio" y simplifican mucho la operación.
                      </p>
                      <button className="btn btn-danger"
                              data-toggle="modal"
                              data-target="#modalConocerAddenda">Algunas addendas disponibles...</button>
                  </div>
              </div>
          </div>
      </div>
  );
};

export {AddendasSection, AddendasSectionTexto};
