import React from 'react';

const Footer = () => (
    <footer className="footer mb-5" data-background-color="black">
        <div className="container">
            <nav>
                <ul>
                    <li>
                        <a href="/SicaiGratuito/">
                            <strong className="text-danger">Aplicación Gratuita</strong>
                        </a>
                    </li>
                    <li>
                        <a href="mailto:direccion@decaber.com">Contacto al Director</a>
                    </li>
                    <li> &copy; {new Date().getFullYear()}, Creado por Digital Factura</li>
                </ul>
            </nav>
        </div>
    </footer>
);

export default Footer;
