import React from 'react';
import '../../css/material-kit.css'
import Caja from '../../img/Caja33.png'
import {urlSicaiInstaller} from '../../data/ConfigData';

const SicaiEscritorioSection = () => (
    <div className="section section-signup page-header FondoSicaiEscritorio" id="SicaiPro">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6"/>
                <div className="col-12 col-md-6">
                    <h1>Sicai Pro</h1>
                    <h3>Administra tu empresa y controla tu información desde tu equipo.</h3>
                    <a className="btn btn-danger" href={urlSicaiInstaller} download="Sicai.exe">Descargar ahora</a>
                </div>
            </div>
        </div>
    </div>
);

const SicaiEscritorioTextoSection = () => (
    <div className="section">
        <div className="container">
            <div className="title">
                <h2>Sicai Pro toda tu información en tu computadora.</h2>
            </div>
            <div className="row">
                <div className="col-12 col-md-9 text-justify">
                    <h4>
                        SICAI PRO (Sistema Integral para el Control y Administración de la Información) es un software
                        para la administración de negocios y de emisión de Comprobantes Fiscales Digitales por Internet
                        (CFDI) mediante el cuál podrás llevar una administración óptima de tus clientes, productos,
                        ventas, almacén, punto de venta y mucho más; todo esto junto con las ventajas que le proporciona la
                        Facturación Electónica soportada por DigitalFactura Proveedor Autorizado de Certificación
                        No. 58354, así garantizas que los comprobantes emitidos serán totalmente válidos y
                        cumplirán con la Ley vigente.
                    </h4>
                    <button className="btn btn-danger"
                            data-toggle="modal"
                            data-target="#modalSicaiEscritorio">Conocer más...</button>
                    <br/>
                    <button className="btn btn-link" data-toggle="modal" data-target="#modalCambioSicai">
                        <strong>Recuperar contraseña de Instalación SICAI</strong>
                    </button>
                </div>
                <div className="col-12 col-md-3 text-center">
                    <img className="img-fluid" alt="Sicai" src={Caja}/>
                </div>
            </div>
        </div>
    </div>
);

export  {SicaiEscritorioSection, SicaiEscritorioTextoSection};
