import React, {Component, Fragment} from 'react';
import $ from 'jquery';
import './css/material-kit.css'
import Fondo from './Components/fondoindex';
import Navigation from './Components/Navigation';
import Modal from './Components/modal'
import {SicaiWeb} from './Components/datosmodales/sicaiweb';
import Footer from './Components/footer';
import PacSection from './Components/Sections/PacSection';
import {SicaiWebSection, SicaiWebTextoSection} from './Components/Sections/SicaiWebSection';
import {SicaiEscritorioSection, SicaiEscritorioTextoSection} from './Components/Sections/SicaiEscritorioSection';
import {SicaiServerSection, SicaiServerTextoSection} from './Components/Sections/SicaiServerSection';
import {DeConectSection, DeConectTextoSection} from './Components/Sections/DeConectSection';
import SicaiEscritorio from './Components/datosmodales/SicaiEscritorio';
import ButtonArrowTop from './Components/ButtonArrowTop';
import {TimbradoSection, TimbradoTextoSection} from './Components/Sections/TimbradoSection';
import {
    DesarrolloSoftwareSection,
    DesarrolloSoftwareTextoSection
} from './Components/Sections/DesarrolloSoftwareSection';
import {AddendasSection, AddendasSectionTexto} from "./Components/Sections/AddendasSection";
import {TimbradoModalPrecios} from "./Components/datosmodales/TimbradoModalPrecios";
import {ConocerAddendaModal} from "./Components/datosmodales/AddendasModal";
import {MasInformacion, MasInformacionTexto} from "./Components/Sections/MasInformacion";
import ContactoModal from "./Components/datosmodales/ContactoModal";
import DConnectModal from "./Components/datosmodales/DConnectModal";
import {ModalRecuperacionSicai} from "./Components/datosmodales/RecuperarSicai";
import {SectionSicaiXml, SectionTextSicaiXml} from "./Components/Sections/SicaiXml";


export default class App extends Component {

    componentDidMount() {
        //Mostrar y ocultar ButtonArrowTop
        $(() => {
            const $win = $(window);
            const pos = 10;
            $win.scroll(function () {
                if ($win.scrollTop() <= pos){
                    $('#Arrowtop').hide(500);
                }
                else {
                    $('#Arrowtop').show(500);
                }
            });
        });
    }

    render() {
        return (
            <Fragment>
                <div className="index-page sidebar-collapse" id="top">
                    <Navigation/>
                    <Fondo/>
                    <div className="main main-raised">
                        <PacSection/>
                        <SicaiWebSection/>
                        <SicaiWebTextoSection/>
                        <SicaiEscritorioSection/>
                        <SicaiEscritorioTextoSection/>
                        <SicaiServerSection/>
                        <SicaiServerTextoSection/>
                        <DeConectSection/>
                        <DeConectTextoSection/>
                        <TimbradoSection/>
                        <TimbradoTextoSection/>
                        <DesarrolloSoftwareSection/>
                        <DesarrolloSoftwareTextoSection/>
                        <AddendasSection/>
                        <AddendasSectionTexto/>
                        <SectionSicaiXml/>
                        <SectionTextSicaiXml/>
                        <MasInformacion/>
                        <MasInformacionTexto/>
                    </div>
                    <Modal idmodal="modalSicaiWeb">
                        <SicaiWeb/>
                    </Modal>
                    <Modal idmodal="modalSicaiEscritorio">
                        <SicaiEscritorio/>
                    </Modal>
                    <Modal idmodal="modalpreciostimbrado">
                        <TimbradoModalPrecios/>
                    </Modal>
                    <Modal idmodal="Contactomodal">
                        <ContactoModal/>
                    </Modal>
                    <Modal idmodal="DConectModal">
                        <DConnectModal/>
                    </Modal>
                    <Modal idmodal="modalConocerAddenda">
                        <ConocerAddendaModal/>
                    </Modal>
                    <Modal idmodal="modalCambioSicai">
                        <ModalRecuperacionSicai Tipo="Sicai"/>
                    </Modal>
                    <Modal idmodal="modalCambioDeConnect">
                        <ModalRecuperacionSicai Tipo="Connect"/>
                    </Modal>
                </div>
                <Footer/>
                <ButtonArrowTop/>
            </Fragment>
        );
    }
}
