import React, {Component, Fragment} from 'react';
import URL from "../../Config";
import axios from 'axios';

class SicaiWeb extends Component{
    state = {
      ListaPrecios: []
    };

    componentDidMount() {
        this.ObtenerPreciosPagina().then();
    }

    ObtenerPreciosPagina = async () => {
        try {
          const Data = await axios.get(URL.PRECIOS);
          this.setState({ListaPrecios: Data.data});
        } catch (e) {
            console.log(e.response);
        }
    };

    render() {
        return(
            <Fragment>
                <div className="modal-header">
                    <h2 className="modal-title">Sicai Web</h2>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="material-icons">clear</i>
                    </button>
                </div>
                <div className="modal-body">
                    <h3>Que incluye?</h3>
                    <ul>
                        <li>Facturación inmediata, directo en línea.</li>
                        <li>Nomina inmediata, directo en línea.</li>
                        <li>Comprobante de Pago inmediata, directo en línea.</li>
                        <li>Comprobante de retenciones, directo en línea.</li>
                        <li>Complementos.</li>
                        <li>Uso gratuito del sistema en linea.</li>
                        <li>Sin pagos anuales.</li>
                        <li>Actualizaciones gratuitas.</li>
                        <li>Comprobantes sin caducidad.</li>
                        <li>Administración de clientes y productos.</li>
                        <li>Sin pago de licencias.</li>
                        <li>Buscador optimizado para el catálogo de productos, servicios y unidades de medida</li>
                        <li>Fácil, Seguro y el más rápido.</li>
                        <li>Resguardo de tus XML por 5 años.</li>
                        <li>Guardado e impresión de prefactura.</li>
                        <li>Nuevo esquema de cancelación de CFDI.</li>
                        <li>Buzón de peticiones de cancelación</li>
                        <li>Servicio de contestación de aceptación o rechazo de canelación.</li>
                        <li>Consulta de estatus de CFDI directo en el SAT.</li>
                        <li>Consulta y envio via correo electrónico de acuses de cancelación.</li>
                        <li>Valida y verifica los comprobantes recibidos y emitidos.</li>
                    </ul>
                    <h4 className="text-danger">Sin Caducidad, Incluye IVA.</h4>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>Paquete</th>
                            <th>Cantidad</th>
                            <th>Precios</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.ListaPrecios.map((X, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{X.nombre}</td>
                                        <td>{X.numfacturas !== '10' ? X.numfacturas + ' créditos' : 'Creditos Ilimitado por un mes'}</td>
                                        <td>$ {X.precio} MXN</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div className="modal-footer">
                    <a href="sicaiwebsys/#/paquetes"
                       className="btn btn-danger">Registrate ahora mismo</a>
                    <button type="button" className="btn btn-danger btn-link" data-dismiss="modal">Cerrar</button>
                </div>
            </Fragment>
        );
    }
}

export {SicaiWeb};
