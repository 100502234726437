import React from 'react';
import Timbre from '../../img/timbre.svg';
import '../../css/material-kit.css';

const TimbradoSection = () => (
    <div className="section section-signup page-header FondoTimbrado" id="Timbrado">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <h1>Timbrado Directo</h1>
                    <h3>Menor costo y tiempos de respuesta enlazados a tu sistema.</h3>
                    <button className="btn btn-danger"
                            data-toggle="modal"
                            data-target="#Contactomodal">Contáctanos</button>
                </div>
                <div className="col-12 col-md-6"/>
            </div>
        </div>
    </div>
);

const TimbradoTextoSection = () => {
    return (
        <div className="section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 text-center">
                        <img alt="timbre" height="200px" src={Timbre}/>
                    </div>
                    <div className="col-12 col-md-8">
                        <h4>
                            Si ya generas el XML v3.3 tan sólo contrata tu paquete de timbrado y nosotros
                            te damos toda la asesoría gratuita para realizar el enlace a nuestro webservice de timbrado.
                        </h4>
                        <ul>
                            <li>Disponibilidad del 99.98%</li>
                            <li>Servicio 24 x 7 x 365</li>
                            <li>Máxima Seguridad para tu información</li>
                            <li>El servicio más rápido del mercado</li>
                            <li>Resguardo de xml´s por 5 años</li>
                            <li>Desarrollo de herramientas personalizadas</li>
                            <li>Siempre actualizado a las disposiciones oficiales</li>
                            <li>Reporte de timbrado por solicitud</li>
                            <li>y mucho mas</li>
                        </ul>
                        <button className="btn btn-danger"
                                data-toggle="modal"
                                data-target="#modalpreciostimbrado">Precios</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {TimbradoSection, TimbradoTextoSection};
