import React, {Component, Fragment} from 'react';
import swal from 'sweetalert2';
import axios from "axios";
import URL from '../../Config';

class TimbradoModalPrecios extends Component{
    state ={ lista: [] };

    ConsultaPrecios = async () => {
        try {
            const dato = await axios.get(URL.PRECIOS_TIMBRE);
            this.setState({lista: [...dato.data]});
        } catch (e) {
            if (e.response.status === 400) {
                swal.fire('Atención', e.response.data.Message, 'warning');
            } else {
                swal.fire('Error', e.response.data.Message, 'error');
            }
        }
    };

    componentDidMount() {
        this.ConsultaPrecios();
    }

    render() {
        return (
            <Fragment>
                <div className="modal-header">
                    <h2 className="modal-title">Precios Timbrado</h2>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="material-icons">clear</i>
                    </button>
                </div>
                <div className="modal-body">
                    <h4 className="text-danger">Sin Caducidad, Incluye IVA.</h4>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>Paquete</th>
                            <th>Cantidad</th>
                            <th>Precio</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.lista.map((X, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{X.Nombre}</td>
                                        <td>{X.Comprobantes} Créditos</td>
                                        <td>$ {X.Precio} MXN</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                    <div className="my-3">
                        <h3><strong>BANORTE:</strong></h3>
                        <h4><strong>Deposito en ventanilla:</strong> 0016547446</h4>
                        <h4><strong>Transferencia electrónica:</strong> 072320000165474467</h4>
                        <h4><strong>a nombre de "Impresos de Caber, S. A. de C. V."</strong></h4>
                    </div>
                    <div className="my-3">
                        <h3><strong>BBVA:</strong></h3>
                        <h4><strong>Depósito en ventanilla:</strong> 0163838765</h4>
                        <h4><strong>Transferencia electrónica:</strong> 012320001638387655</h4>
                        <h4><strong>a nombre de "Impresos de Caber, S. A. de C. V."</strong></h4>
                    </div>
                    <div>
                        <p>Envía tu comprobante de depósito a los correos electrónicos: <br/>
                            administracion@decaber.com<br/>
                            ventas@decaber.com<br/>
                            contacto@decaber.com<br/>
                            Asegúrate de incluir en el comprobante tus datos completos de facturación, de esta manera validaremos el pago relacionado a tu cuenta.
                        </p>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger btn-link" data-dismiss="modal">Cerrar</button>
                </div>
            </Fragment>
        );
    }
}

export {TimbradoModalPrecios}
