import React from 'react';
import PropTypes from 'prop-types'

const Modal = ({idmodal, children}) => (
    <div className="modal fade" id={idmodal} tabIndex="-1" role="dialog">
        <div className="modal-lg modal-dialog" role="document">
            <div className="modal-content">
                {children}
            </div>
        </div>
    </div>
);

Modal.prototype = {
    idmodal: PropTypes.string.isRequired
};

export default Modal;
