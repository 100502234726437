import React, {Fragment} from 'react';
import '../../css/material-kit.css';
import Equipos from "../../img/equipos.png";

const SicaiWebSection = () => (
    <Fragment>
        <div className="section section-signup page-header FondoSeccion"
             id="sicaiweb">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h1>Sicai Web</h1>
                        <h3>Factura desde cualquier lugar y desde cualquier dispositivo.</h3>
                        <a target="_blank" rel="noopener noreferrer" href="sicaiwebsys/#/paquetes"
                           className="btn btn-danger">Registrate aquí</a>
                    </div>
                    <div className="col-12 col-md-6"/>
                </div>
            </div>
        </div>
    </Fragment>
);

const SicaiWebTextoSection = () => (
    <div className="section">
        <div className="container">
            <div className="title">
                <h2>Sicai web utiliza Tecnologías de última generación</h2>
            </div>
            <div className="row">
                <div className="col-12 col-md-3">
                    <img className="img-fluid" alt="Servidor" src={Equipos}/>
                    <div className="text-center text-danger">
                        <strong><p>Emite tus CFDIs desde cualquier dispositivo ó equipo de computo con acceso a internet.</p></strong>
                    </div>
                </div>
                <div className="col-12 col-md-9 text-justify">
                    <h4>Genera cualquier tipo de comprobante fiscal sin necesidad de comprar ningún programa, como
                        Facturas, Notas de Crédito, Notas de Cargo, Recibo de Honorarios, Recibos de Arrendamiento,
                        Nómina, Comprobante de Retenciones, Complemento de Pago, Complemento de Comercio Exterior, etc.
                    </h4>

                    <h4>Administra tus comprobantes contando con un sistema de búsqueda por fecha, nombre y/o R.F.C.,
                        envía e imprime ilimitadamente, verificación de estatus, cancelación, descarga de acuse de
                        recepción y/o cancelación por parte del SAT, personalización de tu comprobante cargando tu
                        logotipo y dando color, inicio de sesión en varios equipos y desde cualquier parte del mundo,
                        todo esto sin costo extra. Mantenemos tu información resguardada y segura.
                    </h4>
                    <h5 className="text-danger">
                        <strong>Contamos con todos los complementos publicados por el SAT</strong>
                    </h5>
                    <button className="btn btn-danger"
                            data-toggle="modal"
                            data-target="#modalSicaiWeb">Conocer más sobre SICAI Web...</button>
                </div>
            </div>
        </div>
    </div>
);

export {SicaiWebSection, SicaiWebTextoSection}
