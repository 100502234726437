import React, {Fragment} from "react";
import HubspotForm from 'react-hubspot-form';

const ContactoModal = () => {
    return (
        <Fragment>
            <div className="modal-header">
                <h4 className="modal-title">Contáctanos</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="material-icons">clear</i>
                </button>
            </div>
            <div className="modal-body">
                <HubspotForm
                    portalId='1735765'
                    formId='31fd5863-be40-42d8-8d6b-61ef8455b3ba'
                    onSubmit={() => console.log('Submit!')}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                />
            </div>
            <div className="modal-footer">
                <button className="btn btn-danger btn-link" data-dismiss="modal">Cerrar</button>
            </div>
        </Fragment>
    );
};

export default ContactoModal;
