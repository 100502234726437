import React, {Fragment} from 'react';
import Ado from '../../img/logos/Autobuses_ADO.svg';
import Cinepolis from '../../img/logos/Cinepolis.svg';
import Elektra from '../../img/logos/Electra.svg';
import Pemex from '../../img/logos/Pemex.svg';
import Femsa from '../../img/logos/Femsa.svg';
import Qualitas from '../../img/logos/Qualitas.svg';
import Soriana from '../../img/logos/Soriana.svg';
import Inbursa from '../../img/logos/Inbursa.svg';
import Chedraui from '../../img/logos/Chedraui.svg';
import ComercialMexicana from '../../img/logos/ComercialMexicana.svg';
import Mabe from '../../img/logos/Mabe.svg';
import Amazon from '../../img/logos/Amazon.svg';
import Vitro from '../../img/logos/Vitro.svg';
import CocaCola from '../../img/logos/Coca-Cola.svg';
import Lala from '../../img/logos/Lala.svg';
import Imss from '../../img/logos/Imss.svg';
import Moctezuma from '../../img/logos/cerveceria-cuauhtemoc-moctezuma.svg';
import Loreal from '../../img/logos/Loreal.svg';
import Colgate from '../../img/logos/Colgate.svg';
import Gnp from '../../img/logos/Gnp.svg';
import Ford from '../../img/logos/Ford_logo.svg';
import Gm from '../../img/logos/Gm.svg';
import Wallmart from '../../img/logos/wall-mart.svg';
import AstraZeneca from '../../img/logos/AstraZeneca.svg';
import Ahmsa from '../../img/logos/Ahmsa.svg';
import Dupont from '../../img/logos/Dupont.svg';
import Audi from '../../img/logos/Audi.svg';
import Gayosso from '../../img/logos/Gayosso.svg';
import Pilgrim from '../../img/logos/Pilgrims.svg';
import Volkswagen from '../../img/logos/Volkswagen.svg';
import SanLuisRassini from '../../img/logos/SanLuisRassini.svg';
import Chrysler from '../../img/logos/Chysler.svg';
import Banorte from '../../img/logos/Banorte.svg';
import Nissan from '../../img/logos/Nissan.svg';
import SanMina from '../../img/logos/Sanmina.svg';
import JhonDeere from '../../img/logos/JhonDeere.svg';
import Prolec from '../../img/logos/Prolec.svg';
import SuperFarmacia from '../../img/logos/SuperFarmacia.svg';


const ConocerAddendaModal = () => {
  return(
      <Fragment>
          <div className="modal-header">
              <h4 className="modal-title">Algunas addendas disponibles si no la tenemos la fabricamos.</h4>
          </div>
          <div className="modal-body">
              <table className="table table-hover">
                  <thead>
                  <tr>
                      <th>nombre</th>
                      <th>logo</th>
                  </tr>
                  </thead>
                  <tbody>
                  <LogotipoAddenda logo={Cinepolis} nombre="Cinepolis"/>
                  <LogotipoAddenda logo={Elektra} nombre="Elektra"/>
                  <LogotipoAddenda logo={Pemex} nombre="Pemex"/>
                  <LogotipoAddenda logo={Femsa} nombre="Femsa"/>
                  <LogotipoAddenda logo={Soriana} nombre="Soriana"/>
                  <LogotipoAddenda logo={Inbursa} nombre="Inbursa"/>
                  <LogotipoAddenda logo={ComercialMexicana} nombre="Comercial Mexicana"/>
                  <LogotipoAddenda logo={Mabe} nombre="Mabe"/>
                  <LogotipoAddenda logo={Amazon} nombre="Amazon"/>
                  <LogotipoAddenda logo={Loreal} nombre="Loreal"/>
                  <LogotipoAddenda logo={Colgate} nombre="Colgate"/>
                  <LogotipoAddenda logo={Gnp} nombre="Seguros GNP"/>
                  <LogotipoAddenda logo={Ford} nombre="Ford"/>
                  <LogotipoAddenda logo={Wallmart} nombre="Wall-Mart"/>
                  <LogotipoAddenda logo={AstraZeneca} nombre="AstraZeneca"/>
                  <LogotipoAddenda logo={Prolec} nombre="Prolec GE"/>
                  <LogotipoAddenda logo={Chrysler} nombre="Chrysler"/>
                  <LogotipoAddenda logo={Banorte} nombre="Banorte"/>
                  <LogotipoAddenda logo={SuperFarmacia} nombre="Super Farmacia"/>
                  <LogotipoAddenda logo={Ado} nombre="Autobuses ADO"/>
                  <LogotipoAddenda logo={CocaCola} nombre="Coca Cola"/>
                  <LogotipoAddenda logo={Qualitas} nombre="Qualitas"/>
                  <LogotipoAddenda logo={Chedraui} nombre="Chedraui"/>
                  <LogotipoAddenda logo={Vitro} nombre="Grupo Vitro"/>
                  <LogotipoAddenda logo={Lala} nombre="Lala"/>
                  <LogotipoAddenda logo={Moctezuma} nombre="Cervecería Moctezuma"/>
                  <LogotipoAddenda logo={Ahmsa} nombre="Altos Hornos de México"/>
                  <LogotipoAddenda logo={Dupont} nombre="Dupont"/>
                  <LogotipoAddenda logo={Audi} nombre="Audi"/>
                  <LogotipoAddenda logo={Gayosso} nombre="Gayosso"/>
                  <LogotipoAddenda logo={Pilgrim} nombre="Pilgrim´s"/>
                  <LogotipoAddenda logo={SanLuisRassini} nombre="San Luis Rassini"/>
                  <LogotipoAddenda logo={SanMina} nombre="Sanmina"/>
                  <LogotipoAddenda logo={JhonDeere} nombre="Jhon Deere"/>
                  <LogotipoAddenda logo={Nissan} nombre="Nissan"/>
                  <LogotipoAddenda logo={Volkswagen} nombre="Volkswagen"/>
                  <LogotipoAddenda logo={Gm} nombre="Generals Motors"/>
                  <LogotipoAddenda logo={Imss} nombre="Imss"/>
                  </tbody>
              </table>
          </div>
          <div className="modal-footer">
              <button className="btn btn-danger btn-link" data-dismiss="modal">Cerrar</button>
          </div>
      </Fragment>
  );
};

const LogotipoAddenda = ({logo, nombre}) => {
    return (
        <tr>
            <td><h3>{nombre}</h3></td>
            <td><img alt={nombre} width="100" src={logo}/></td>
        </tr>
    );
};

export {ConocerAddendaModal};
