import React from 'react';
import PropTypes from 'prop-types';
import {Scroll} from '../Functions/GeneralFunctions';
import '../css/material-kit.css';

const Navigation = () => (
    <nav className="navbar navbar-transparent navbar-color-on-scroll fixed-top navbar-expand-lg" id="sectionsNav">
        <div className="container">
            <div className="navbar-translate">
                <h4>Digital Factura</h4>
                <ButtonNavigationCollapse/>
            </div>
            <MenuTop/>
        </div>
    </nav>
);

export default Navigation;

const ButtonNavigationCollapse = () => (
    <button className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            aria-expanded="false"
            aria-label="Toggle navigation">
        <span className="sr-only">Toggle navigation</span>
        <span className="navbar-toggler-icon"/>
        <span className="navbar-toggler-icon"/>
        <span className="navbar-toggler-icon"/>
    </button>
);

const MenuTop = () => (
    <div className="collapse navbar-collapse">
        <ul className="navbar-nav ml-auto">
            <ButtonDropdown/>
            <li className="nav-item">
                <a className="nav-link"
                   href="https://www.digitalfactura.com/sicaiwebsys/#/login"
                   onClick={() => Scroll('.section-download')}>
                    <i className="material-icons">account_circle</i> Facturar Aquí
                </a>
            </li>
            <ButtonLi url='https://twitter.com/Digital_Factura'
                      icon='fa fa-twitter'
                      titledata='Follow us on Twitter'/>
            <ButtonLi url='https://es-la.facebook.com/digitalfactura/'
                      icon='fa fa-facebook-square'
                      titledata='Like us on Facebook'/>
        </ul>
    </div>
);

const ButtonDropdown = () => (
    <li className="dropdown nav-item">
        <h4 className="dropdown-toggle nav-link" data-toggle="dropdown">
            <i className="material-icons">apps</i> Nuestros Productos
        </h4>
        <div className="dropdown-menu dropdown-with-icons">
            <h4 className="dropdown-item" onClick={() => Scroll('#sicaiweb')}>
                <i className="material-icons">restore_page</i> Sicai Web
            </h4>
            <h4 className="dropdown-item" onClick={() => Scroll('#SicaiPro')}>
                <i className="material-icons">work</i> Sicai Pro
            </h4>
            <h4 className="dropdown-item" onClick={() => Scroll('#SicaiServer')}>
                <i className="material-icons">assignment</i> Sicai Server
            </h4>
            <h4 className="dropdown-item" onClick={() => Scroll('#DeConnect')}>
                <i className="material-icons">compare_arrows</i> DeConect
            </h4>
            <h4 className="dropdown-item" onClick={() => Scroll('#Timbrado')}>
                <i className="material-icons">fingerprint</i> Timbrado
            </h4>
            <h4 className="dropdown-item" onClick={() => Scroll('#desarrollosoftware')}>
                <i className="material-icons">touch_app</i> Desarrollo
            </h4>
            <h4 className="dropdown-item" onClick={() => Scroll('#addendas')}>
                <i className="material-icons">spellcheck</i> Addendas
            </h4>
            <h4 className="dropdown-item" onClick={() => Scroll('#sicaiXML')}>
                <i className="material-icons">spellcheck</i> Sicai XML Descargas
            </h4>
            <h4 className="dropdown-item" onClick={() => Scroll('#masinfo')}>
                <i className="material-icons">help</i> Más Información
            </h4>
        </div>
    </li>
);

const ButtonLi = ({icon, url, titledata}) => (
    <li className="nav-item">
        <a className="nav-link"
           rel="noopener noreferrer"
           title=""
           data-placement="bottom"
           target="_blank"
           href={url}
           data-original-title={titledata}>
            <i className={icon}/>
        </a>
    </li>
);
ButtonLi.prototype = {
    icon: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    titledata: PropTypes.string.isRequired
};
