import React from 'react';
import SicaiXml from '../../img/LogoSicaiXml.jpg';
import Precios from  '../../img/precios.jpg';

const SectionSicaiXml = () => {
  return(
      <div className="section section-signup page-header FondoXmlDescarga"
           id="sicaiXML">
          <div className="container">
              <div className="row">
                  <div className="col-12 col-md-6">
                      <h1>Sicai Xml Descarga</h1>
                      <h3>Descarga todos tus comprobantes emitidos y recibidos de forma sencilla</h3>
                      <a target="_blank" rel="noopener noreferrer"
                         href="http://digitalfactura.com/contenido/sicai/downloads/SicaiXML.exe"
                         download="Sicaixmldescarga.exe" className="btn btn-danger">
                          Descargar ahora
                      </a>
                  </div>
                  <div className="col-12 col-md-6"/>
              </div>
          </div>
      </div>
  );
};

const SectionTextSicaiXml = () => {
  return (
      <div className="section">
          <div className="container">
              <div className="title">
                  <h2>Sicai Xml Descarga te conecta directo al SAT</h2>
              </div>
              <div className="row">
                  <div className="col-12 col-md-3">
                      <img className="img-fluid" alt="Servidor" src={SicaiXml}/>
                      <div className="text-center text-danger">
                          <strong><p>Que esperas obtén un mes GRATIS</p></strong>
                      </div>
                  </div>
                  <div className="col-12 col-md-9 text-justify">
                      <h4>
                          Administra tus comprobantes que has descargado del SAT a travez de la aplicación y
                          exportalos a excel.
                      </h4>

                      <h4>Facil de usar, puedes descargar hasta 2000 comprobantes por día de cada RFC con el
                          que ingreses.
                      </h4>
                      <br/>
                      <img className="img-fluid" alt="Precios" src={Precios}/>
                  </div>
              </div>
          </div>
      </div>
  );
};

export {SectionSicaiXml, SectionTextSicaiXml};
