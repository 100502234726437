import React from 'react';
import Redes from '../../img/redes.svg';

const SicaiServerSection = () => (
    <div className="section section-signup page-header FondeoSicaiServer" id="SicaiServer">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <h1>Sicai Server</h1>
                    <h3>Administra tu empresa y maneja terminales para acceder a tu información.</h3>
                    <button className="btn btn-danger" data-toggle="modal" data-target="#Contactomodal">Contáctanos</button>
                </div>
                <div className="col-12 col-md-6"/>
            </div>
        </div>
    </div>
);

const SicaiServerTextoSection = () => (
    <div className="section">
        <div className="container">
            <div className="title">
                <h2>Sicai Server todos tus equipos centralizados a tu servidor</h2>
            </div>
            <div className="row subir">
                <div className="col-12 col-md-3 text-center">
                    <img alt="red" height="200px" src={Redes}/>
                </div>
                <div className="col-12 col-md-9 text-justify">
                    <h4>
                        SICAI Server es todo lo que obtienes de SICAI pro pero potenciado para trabajar en redes con terminales,
                        así podrás tener distribuido por sucursales y usuarios trabajando a la vez y todo almacenado en
                        tu servidor.
                    </h4>
                    <h4>
                        Lleva el control de accesos a la aplicación y distribuye las responsabilidades con tu equipo de
                        trabajo, obtén control total.
                    </h4>
                    <button className="btn btn-danger"
                            data-toggle="modal"
                            data-target="#modalSicaiEscritorio">Conoce más...</button>
                </div>
            </div>
        </div>
    </div>
);

export {SicaiServerSection, SicaiServerTextoSection};
