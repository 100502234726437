import React from 'react';
import flechas from "../img/FlechasScroll.png";
import {Scroll} from '../Functions/GeneralFunctions';

const ButtonArrowTop = () => (
    <div className="text-center">
        <img id='Arrowtop'
             className="FlechasInicio"
             alt="inicio"
             src={flechas}
             onClick={() => Scroll('#top')}/>
    </div>
);

export default ButtonArrowTop;
