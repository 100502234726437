import React, {Component, Fragment} from  'react';
import swal from 'sweetalert2';
import {Captcha, ValidCaptcha} from '../../Functions/Capcha.function';
import axios from 'axios';
import URL from '../../Config'

export class ModalRecuperacionSicai extends Component{
    constructor(props) {
        super(props);
        this.state = {
            Tipo: this.props.Tipo === 'Sicai' ? 'SICAI' : 'DeConnect',
            correo: '',
            captcha: '',
            humano: ''
        }
    }

    componentDidMount() {
        this.setState({...this.state, captcha: Captcha()});
    }

    NuevoCaptcha = () => {
        this.setState({...this.state, captcha: Captcha()});
    };

    Enviar = () => {
        if (this.state.correo.trim().length === 0) {
            swal.fire('Atención', 'Es necesario escribir el correo con el cual se registró', 'warning');
        } else if (!ValidCaptcha(this.state.captcha, this.state.humano)) {
            swal.fire('Atención', 'El captcha no es correcto', 'warning');
        } else {
            axios.post(URL.EnvioRecuracion, {
                tipo: this.state.Tipo === 'SICAI' ? '1' : '2',
                correo: this.state.correo
            }).then(response => {
                if (response.status === 200) {
                    swal.fire('Exitoso', response.data, 'success');
                    this.setState({correo: '', humano: '', captcha: Captcha()});
                } else {
                    swal.fire('Atención', response.data, 'warning');
                }
            }).catch(err => {
                if (err.response.status === 400) {
                    swal.fire('Atención', err.response.data.Message, 'warning');
                } else {
                    swal.fire('Error', 'Ocurrió un error en el servidor', 'error');
                }
            });
        }
    };

    handlerInput = (e) => {
        e.preventDefault();
        this.setState({...this.state, [e.target.name]: e.target.value});
    };

    render() {
        return (
            <Fragment>
                <div className="modal-header">
                    <h2 className="text-danger">Recuperar Contraseña de {this.state.Tipo}</h2>
                    <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                        <i className="material-icons">clear</i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label>Correo electrónico (El que usaste para registrarte)</label>
                       <input className="form-control"
                              placeholder="Escribe tu correo"
                              name='correo'
                              onChange={this.handlerInput}
                              value={this.state.correo}/>
                    </div>
                    <h3 className="text-success">{this.state.captcha}</h3>
                    <p onClick={this.NuevoCaptcha}>click para nuevo captcha</p>
                    <div className="form-group">
                        <label>Escribe el captcha</label>
                        <input className="form-control"
                               onChange={this.handlerInput}
                               placeholder="¿Eres humano?"
                               name={'humano'}
                               value={this.state.humano}/>
                    </div>
                    <button className="btn btn-danger" onClick={this.Enviar}>Enviar</button>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger btn-link" data-dismiss="modal">Cerrar</button>
                </div>
            </Fragment>
        );
    }
}
