import React, {Fragment} from 'react';
import TablaSicai from '../../data/TablaSicai';
import '../../css/material-kit.css';
import Check from '../../img/check.svg';
import None from '../../img/none.svg';
import {urlSicaiInstaller} from '../../data/ConfigData';

const SicaiEscritorio = () => (
    <Fragment>
        <div className="modal-header">
            <h2 className="modal-title">Sicai Pro</h2>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="material-icons">clear</i>
            </button>
        </div>
        <div className="modal-body">
            <a className="btn btn-danger" href={urlSicaiInstaller} download="Sicai.exe">Descargar ahora</a>
            <div className="anchovista">
                <p className="text-danger"><strong>Los Precios incluyen iva</strong></p>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th>Características</th>
                        <th>Sicai Web</th>
                        <th>Sicai Estandar</th>
                        <th>
                            Sicai Pro <br/>
                            $4,058.84
                        </th>
                        <th>
                            Sicai Ilimitado <br/>
                            $7,538.84
                        </th>
                        <th>
                            Sicai Server <br/>
                            $8,118.84
                        </th>
                        <th>
                            Sicai Server Ilimitado <br/>
                            $11,598.84
                        </th>
                        <th><strong>Sicai <br/> Cloud<i><span className="text-danger"> Próximamente</span></i></strong></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        TablaSicai.map((X, index) => <RowTable key={index} Row={X}/>)
                    }
                    </tbody>
                </table>
            </div>
        </div>
        <div className="modal-footer">
            <button className="btn btn-danger btn-link" data-dismiss="modal">Cerrar</button>
        </div>
    </Fragment>
);

const RowTable = ({Row}) => {
    const {
        caracteristica,
        sicaiweb,
        sicaiestandar,
        sicaipro,
        sicaiilimitado,
        sicaiserver,
        sicaiserverilimitado,
        sicaicloud
    } = Row;
  return (
      <tr>
          <td>{caracteristica}</td>
          <td>
              {
                  sicaiweb ?
                      <img alt="Si" src={Check} width="20px"/> : <img alt="No" src={None} width="20px"/>
              }
          </td>
          <td>
              {
                  sicaiestandar ?
                      <img alt="Si" src={Check} width="20px"/> : <img alt="No" src={None} width="20px"/>
              }
          </td>
          <td>
              {
                  sicaipro ?
                      <img alt="Si" src={Check} width="20px"/> : <img alt="No" src={None} width="20px"/>
              }
          </td>
          <td>
              {
                  sicaiilimitado ?
                      <img alt="Si" src={Check} width="20px"/> : <img alt="No" src={None} width="20px"/>
              }
          </td>
          <td>
              {
                  sicaiserver ?
                      <img alt="Si" src={Check} width="20px"/> : <img alt="No" src={None} width="20px"/>
              }
          </td>
          <td>
              {
                  sicaiserverilimitado ?
                      <img alt="Si" src={Check} width="20px"/> : <img alt="No" src={None} width="20px"/>
              }
          </td>
          <td>
              {
                  sicaicloud ?
                      <img alt="Si" src={Check} width="20px"/> : <img alt="No" src={None} width="20px"/>
              }
          </td>
      </tr>
  );
};




export default SicaiEscritorio;
