import React from 'react';
import {urlDeConectInstaller} from "../../data/ConfigData";
import Convert from '../../img/convert.svg';

const DeConectSection = () => (
    <div className="section section-signup page-header FondoDeconnect" id="DeConnect">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6"/>
                <div className="col-12 col-md-6">
                    <h1>DeConect</h1>
                    <h3>Convierte y timbra tus comprobantes versión 3.2 a 3.3 con los requisitos siempre actualizados.</h3>
                    <button className="btn btn-danger" data-toggle="modal" data-target="#Contactomodal">Contáctanos</button>
                    <br/>
                    <a className="btn btn-danger" href={urlDeConectInstaller} download="DeConect.exe">Descargar Ahora</a>
                </div>
            </div>
        </div>
    </div>
);

const DeConectTextoSection = () => (
    <div className="section">
        <div className="container">
            <div className="title">
                <h2>DeConnect, una solución que te permite seguir generando tus comprobantes a partir de formatos anteriores.</h2>
            </div>
            <div className="row">
                <div className="col-12 col-md-9 text-justify">
                    <h4>
                        Olvida las actualizaciones costosas y cambio de formas de trabajo, DeConnect te facilita
                        la conversión y timbrado de tus comprobantes a la versión actual de xml, además de la generación
                        del pdf.
                    </h4>
                    <h4>
                        Con DeConnect estarás siempre actualizado.
                    </h4>
                    <button type="button"
                            className="btn btn-danger"
                            data-toggle="modal"
                            data-target="#DConectModal">Conoce más...</button>
                </div>
                <div className="col-12 col-md-3">
                    <img alt="Conversión" className="img-fluid" src={Convert}/>
                </div>
            </div>
            <button className="btn btn-link" data-toggle="modal" data-target="#modalCambioDeConnect">
                <strong>Recuperar contraseña <br/> de Instalación DeConnect</strong>
            </button>
        </div>
    </div>
);

export {DeConectSection, DeConectTextoSection};
