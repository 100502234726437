const TablaSicai = [
    {
        caracteristica: 'Timbrado Ilimitado',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: false,
        sicaiilimitado: true,
        sicaiserver: false,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Servicio 100% en linea',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: false,
        sicaiilimitado: false,
        sicaiserver: false,
        sicaiserverilimitado: false,
        sicaicloud: true
    },
    {
        caracteristica: 'Adaptable a equipos móviles y de escritorio',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: false,
        sicaiilimitado: false,
        sicaiserver: false,
        sicaiserverilimitado: false,
        sicaicloud: true
    },
    {
        caracteristica: 'Emisión y timbrado de facturas',
        sicaiweb: true,
        sicaiestandar: true,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Emisión y timbrado de Nómina',
        sicaiweb: true,
        sicaiestandar: true,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Emisión y timbrado de complementos de recepción de pagos',
        sicaiweb: true,
        sicaiestandar: true,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Emisión y timbrado de complementos de impuestos locales',
        sicaiweb: true,
        sicaiestandar: true,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Administración de productos',
        sicaiweb: true,
        sicaiestandar: true,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Administración de clientes',
        sicaiweb: true,
        sicaiestandar: true,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Cancelación',
        sicaiweb: true,
        sicaiestandar: true,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Multiempresa',
        sicaiweb: false,
        sicaiestandar: true,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: false
    },
    {
        caracteristica: 'Control de accesos',
        sicaiweb: false,
        sicaiestandar: true,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Complemento INE',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Servicios parciales de construcción',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Complementos Notarios Públicos',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Complementos Notarios Públicos',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Complementos Comercio exterior',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Emisión y timbrado de comprobantes de retenciones y sus complementos',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Importación de productos y clientes',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Verificación de estatus de comprobantes en el SAT',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Ordenes de compra',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Cotizaciones',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Tickets de venta',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Generación de reportes en excel',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Calculo de nómina',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Generación de nómina con datos precargados',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Almacén (Inventario y Existencias)',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Punto de venta',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: false
    },
    {
        caracteristica: 'Cortes de caja y cortes programados',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: false
    },
    {
        caracteristica: 'Reportes de venta',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Bancos',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Cuentas por pagar',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Cuentas por cobrar',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: true,
        sicaiilimitado: true,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Funcionamiento en red',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: false,
        sicaiilimitado: false,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Acceso simultaneos de usuarios',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: false,
        sicaiilimitado: false,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Control total de tus sucursales',
        sicaiweb: false,
        sicaiestandar: false,
        sicaipro: false,
        sicaiilimitado: false,
        sicaiserver: true,
        sicaiserverilimitado: true,
        sicaicloud: true
    },
    {
        caracteristica: 'Validación y verificación de CFDIs recibidos',
        sicaiweb: true,
        sicaiestandar: false,
        sicaipro: false,
        sicaiilimitado: false,
        sicaiserver: false,
        sicaiserverilimitado: false,
        sicaicloud: true
    }
];

export default TablaSicai;
