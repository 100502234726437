import React, {Fragment} from 'react';
import Check from '../../img/check.svg';
import None from '../../img/none.svg';

const DConnectModal = () => {
    const datos = [
        {caracteristica: 'Timbrado de Facturas 3.3', estandar: true, pro: true},
        {caracteristica: 'Timbrado de Nómina 1.2', estandar: true, pro: true},
        {caracteristica: 'Timbrado de complemento de Recepción de Pago', estandar: true, pro: true},
        {caracteristica: 'Timbrado de complemento de Impuestos Locales', estandar: true, pro: true},
        {caracteristica: 'Generación de PDF', estandar: true, pro: true},
        {caracteristica: 'Integración de catálogos de productos y servicios del SAT', estandar: true, pro: true},
        {caracteristica: 'Integración de catálogos de unidad de medida SAT', estandar: true, pro: true},
        {caracteristica: 'Servicio de cancelación', estandar: true, pro: true},
        {caracteristica: 'Memorización de unidades de medida del SAT', estandar: false, pro: true},
        {caracteristica: 'Memorización de claves de productos y servicios del SAT', estandar: false, pro: true},
        {caracteristica: 'Regeneración de XML timbrado', estandar: false, pro: true},
        {caracteristica: 'Regeneración de PDFs', estandar: false, pro: true},
        {caracteristica: 'Reporte en Excel de XMLs timbrados', estandar: false, pro: true}
    ];
    return (
        <Fragment>
            <div className="modal-header">
                <h4 className="modal-title">DeConect</h4>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-12">
                        <p className="text-justify">
                            <strong>SICAI DeConect 3.3</strong> es una herramienta que te permite conectarte al
                            servicio de DigitalFactura para el timbrado de tus comprobantes fiscales en versión 3.3
                            además, si no quieres cambiar o actualizar tu software de facturación, te permite convertir
                            tus comprobantes fiscales en versión 3.2 a la versión 3.3 para que no te quedes sin
                            timbrar tus comprobantes. Todo esto junto con las ventajas que le proporciona la
                            Facturación Electónica soportada por DigitalFactura Proveedor Autorizado de Certificación
                            No. 58354, esto te garantiza que los comprobantes emitidos serán totalmente válidos y
                            cumplirán con la Ley vigente además que podrás agregarle herramientas personalizadas que se
                            ajusten a tus necesidades.
                        </p>
                        <p className="text-justify">
                            <strong>Requerimientos Mínimos.</strong><br/>
                            El equipo donde instales SICAI DeConnect debera cumplir con los siguientes requisitos, de lo
                            contrario el Software SICAI Deconnect no podrá ejecutarse adecuadamente o no se instalará.
                        </p>
                        <ul className="text-justify">
                            <li>Sistema compatibles: Windows 7 (necesario Service Pack 1), Windows 8, y Windows
                                8.1, Windows 10</li>
                            <li>Es necesario contar con las actualizaciones mas recientes para la compatibilidad
                                del sistema.</li>
                            <li>Una conexión estable a Internet.</li>
                            <li>Se requiere tener las más recientes actualizaciones de tu sistema operativo.</li>
                            <li>Microsoft .NET Framework 4.5</li>
                            <li>2 GB de memoria RAM o superior.</li>
                            <li>Espacio en el disco duro de 10 GB o superior.</li>
                            <li>Procesador de 1.2 GHZ o superior (recomendable doble núcleo o superior).</li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Características</th>
                                <th>Estandar (Gratuito)</th>
                                <th>Pro ($4499.00)</th>

                            </tr>
                            </thead>
                            <tbody>
                            {
                                datos.map((X, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{X.caracteristica}</td>
                                            <td>{X.estandar ? <img alt="Si" src={Check} width="20px"/> : <img alt="No" src={None} width="20px"/>}</td>
                                            <td>{X.pro ? <img alt="Si" src={Check} width="20px"/> : <img alt="No" src={None} width="20px"/>}</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-danger btn-link" data-dismiss="modal">Cerrar</button>
            </div>
        </Fragment>
    );
};

export default DConnectModal;
